import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"

const PrivacyPolicy = () => (
	<Layout>
		<SEO
			title="Privacy Policy"
			description="NARM Training Institute LLC, and Kammer Health and Wellness, LLC (“Company,” “we,” or “us”) respects your privacy and is committed to protecting it through this Privacy Policy."
		/>
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h1 />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div className="intro mb-4 ">
					<div className="row">
						<div className="col-md-9 mx-auto text-center">
							<h2 className="text-primary">
								<span className="text-orange">Privacy</span> Policy
							</h2>
							<p className="text-muted">
								{" "}
								<strong />{" "}
							</p>
							<hr />
						</div>
					</div>
				</div>
				<div class="row justify-content-between align-items-center">
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							{" "}
							<strong>
								NARM Training Institute LLC, and Kammer Health and Wellness, LLC
								(“Company,” “we,” or “us”):
							</strong>{" "}
							respects your privacy and is committed to protecting it through
							this Privacy Policy. This Privacy Policy governs your access to
							and use of www.narmtraining.com, including any content,
							functionality and services offered on or through
							www.narmtraining.com (the “Website”), whether as a guest or a
							registered user. When accessing the Website, we will learn certain
							information about you, both automatically and through voluntary
							actions you may take, during your visit. This policy applies to
							information we collect on the Website and in email, text, or other
							electronic messages between you and the Website. Please read the
							Privacy Policy carefully before you start to use the Website. By
							using the Website or by clicking to accept or agree to the Terms
							of Use when this option is made available to you, you accept and
							agree to be bound and abide by the Privacy Policy. Through the use
							of www.narmtraining.com you are herein consenting to the following
							data procedures expressed within this agreement.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							{" "}
							<strong>Children Under The Age Of 13:</strong> Our Website is not
							intended for children under 13 years of age. No one under age 13
							may provide any information to or on the Website. We do not
							knowingly collect personal information from children under 13. If
							you are under 13, do not use or provide any information on this
							Website or on or through any of its features/register on the
							Website, make any purchases through the Website, use any of the
							interactive or public comment features of this Website or provide
							any information about yourself to us, including your name,
							address, telephone number, email address, or any screen name or
							user name you may use.
						</p>
						<p>
							If we learn we have collected or received personal information
							from a child under 13 without verification of parental consent, we
							will delete that information. If you believe we might have any
							information from or about a child under 13, please contact us at
							<a href="mailto:support@narmtraining.com">
								support@narmtraining.com
							</a>
							.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							{" "}
							<strong>Information We Collect About You:</strong> When you access
							the Website, the Company will learn certain information about you
							during your visit. The Website provides various places for users
							to provide information. We collect information that users provide
							by filling out forms on the Website, communicating with us via
							contact forms, providing comments or other feedback, and providing
							information when ordering a product via the Website. We use
							information you provide to us to provide the service and/or
							deliver the requested product, to improve our overall performance,
							and to provide you with offers, promotions, and information.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							{" "}
							<strong>
								Information We Collect Through Automatic Data Collection
								Technology:
							</strong>{" "}
							When you access As you navigate through our Website, we may use
							automatic data collection technologies including Google Analytics
							to collect certain information about your equipment, browsing
							actions, and patterns. This will generally include information
							about your location, your traffic pattern through our website, and
							any communications between your computer and our Website. Among
							other things, we will collect data about the type of computer you
							use, your Internet connection, your IP address, your operating
							system, and your browser type. The information we collect
							automatically is used for statistical data and will not include
							personal information. We use this data to improve our Website and
							our service offerings. To the extent that you voluntarily provide
							personal information to us, our systems will associate the
							automatically collected information with your personal
							information.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							{" "}
							<strong>Use of Cookies And Pixels:</strong> Similar to other
							commercial websites, our website utilizes a standard technology
							called “cookies” and server logs to collect information about how
							our site is used. Information gathered through cookies and server
							logs may include the date and time of visits, the pages viewed,
							time spent at our site, and the websites visited just before and
							just after our own, as well as your IP address. A cookie is a very
							small text document, which often includes an anonymous unique
							identifier. When you visit a website, that site’s computer asks
							your computer for permission to store this file in a part of your
							hard drive specifically designated for cookies. Each website can
							send its own cookie to your browser if your browser’s preferences
							allow it, but (to protect your privacy) your browser only permits
							a website to access the cookies it has already sent to you, not
							the cookies sent to you by other sites. The Company reserves the
							right to use technological equivalents of cookies, including
							social media pixels. These pixels allow social media sites to
							track visitors to outside websites so as to tailor advertising
							messages users see while visiting that social media website. The
							Company reserves the right to use these pixels in compliance with
							the policies of the various social media sites.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							{" "}
							<strong>Third Party Use Of Cookies:</strong> Some content or
							applications, including advertisements, on the Website are served
							by third-parties, including advertisers, ad networks and servers,
							content providers, and application providers. These third parties
							may use cookies alone or in conjunction with web beacons or other
							tracking technologies to collect information about you when you
							use our website. The information they collect may be associated
							with your personal information or they may collect information,
							including personal information, about your online activities over
							time and across different websites and other online services. They
							may use this information to provide you with interest-based
							(behavioral) advertising or other targeted content. We do not
							control these third parties’ tracking technologies or how they may
							be used. If you have any questions about an advertisement or other
							targeted content, you should contact the responsible provider
							directly.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							{" "}
							<strong>International Transfers:</strong> Your information,
							including Personal Information, may be transferred to — and
							maintained on — computers located outside of your state, province,
							country or other governmental jurisdiction where the data
							protection laws may differ than those from your jurisdiction. If
							you are located outside United States and choose to provide
							information to us, please note that we transfer the information,
							including Personal Information, to the United States and process
							it there. Your consent to this Privacy Policy followed by your
							submission of such information represents your agreement to that
							transfer.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							<strong>Links To Other Sites:</strong> Our Service may contain
							links to other sites that are not operated by us. If you click on
							a third party link, you will be directed to that third party’s
							site. We strongly advise you to review the Privacy Policy of every
							site you visit. We have no control over, and assume no
							responsibility for the content, privacy policies or practices of
							any third party sites or services.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							<strong>Email Information:</strong> If you choose to correspond
							with us through email, we may retain the content of your email
							messages together with your email address and our responses. We
							provide the same protections for these electronic communications
							that we employ in the maintenance of information received online,
							mail, and telephone. This also applies when you register for our
							website, sign up through any of our forms using your email address
							or make a purchase on this site. For further information see the
							email policies below.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							<strong>Email Policies:</strong> We are committed to keeping your
							e-mail address confidential. We do not sell, rent, or lease our
							subscription lists to third parties, and will not disclose your
							email address to any third parties except as allowed in the
							section titled Disclosure of Your Information. We will maintain
							the information you send via e-mail in accordance with applicable
							federal law. In compliance with the CAN-SPAM Act, all e-mails sent
							from our organization will clearly state who the e-mail is from
							and provide clear information on how to contact the sender. In
							addition, all e-mail messages will also contain concise
							information on how to remove yourself from our mailing list so
							that you receive no further e-mail communication from us. Our
							emails provide users the opportunity to opt-out of receiving
							communications from us and our partners by reading the unsubscribe
							instructions located at the bottom of any e-mail they receive from
							us at anytime. Users who no longer wish to receive our newsletter
							or promotional materials may opt-out of receiving these
							communications by clicking on the unsubscribe link in the e-mail.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							<strong>How And Why We Collect Information:</strong>The Company
							collects your information in order to record and support your
							participation in the activities you select. We use this
							information to track your preferences and to keep you informed
							about the products and services you have selected to receive and
							any related products and/or services. As a visitor to this
							Website, you can engage in most activities without providing any
							personal information. It is only when you seek to download
							resources and/or purchase products that you are required to
							provide information. If you are outside the European Union and opt
							to receive any free resources, participate in any free training
							programs, register for a webinar, register for a live event,
							register for a seminar, or purchase any products sold by the
							Company on this Website, we will automatically enroll you to
							receive our promotional email communications, including our weekly
							magazine. If you do not wish to receive this communication, you
							can unsubscribe anytime. We include an “unsubscribe” link at the
							bottom of every email we send. If you ever have trouble
							unsubscribing, you can send an email to{" "}
							<a href="mailto:support@narmtraining.com">
								support@narmtraining.com
							</a>
							requesting to unsubscribe from future emails. If you are in the
							European Union and opt to receive any free resources, participate
							in any free training programs, register for a webinar, register
							for a live event, register for a seminar, or purchase any products
							sold by the Company on this Website, we will only enroll you to
							receive our free email communication if you affirmatively consent
							to it. If you do not wish to receive this communication, you can
							unsubscribe anytime. We include an “unsubscribe” link at the
							bottom of every email we send. If you ever have trouble
							unsubscribing, you can send an email to{" "}
							<a href="mailto:support@narmtraining.com">
								support@narmtraining.com
							</a>
							requesting to unsubscribe from future emails.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							<strong>
								How Do We Use the Information That You Provide to Us?
							</strong>
							We use personal information for purposes of presenting our Website
							and its contents to you, providing you with information, providing
							you with offers for products and services, providing you with
							information about your subscriptions and products, carrying out
							any contract between you and the Company, administering our
							business activities, providing customer service, and making
							available other items and services to our customers and
							prospective customers. From time-to-time, we may use the
							information you provide to us to make you offers to purchase
							products and services provided by third parties in exchange for a
							commission to be paid to us by such third parties. Should you opt
							to take part in such promotions, the third parties will receive
							your information. From time-to-time, we may use the information
							you provide to us to display advertisements to you that are
							tailored to your personal characteristics, interests, and
							activities.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							<strong>Disclosure Of Your Information:</strong>
							As a general rule, we do not sell, rent, lease or otherwise
							transfer any information collected whether automatically or
							through your voluntary action. We may disclose your personal
							information to our subsidiaries, affiliates, and service providers
							for the purpose of providing our services to you. We may disclose
							your personal information to a third party, including a lawyer or
							collection agency, when necessary to enforce our terms of service
							or any other agreement between you and the Company. We may
							disclose information when legally compelled to do so, in other
							words, when we, in good faith, believe that the law requires it or
							for the protection of our legal rights or when compelled by a
							court or other governmental entity to do so.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							<strong>
								How Do We Protect Your Information and Secure Information
								Transmissions?
							</strong>
							As a general rule, we do not sell, rent, lease or otherwise
							transfer any information collected whether automatically or
							through your voluntary action. We may disclose your personal
							information to our subsidiaries, affiliates, and service providers
							for the purpose of providing our services to you. We may disclose
							your personal information to a third party, including a lawyer or
							collection agency, when necessary to enforce our terms of service
							or any other agreement between you and the Company. We may
							disclose information when legally compelled to do so, in other
							words, when we, in good faith, believe that the law requires it or
							for the protection of our legal rights or when compelled by a
							court or other governmental entity to do so.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							<strong>Policy Changes:</strong>
							It is our policy to post any changes we make to our privacy policy
							on this page. If we make material changes to how we treat our
							users’ personal information, we will notify you by email to the
							email address specified in your account and/or through a notice on
							the Website home page. The date the privacy policy was last
							revised is identified at the bottom of the page. You are
							responsible for ensuring we have an up-to-date active and
							deliverable email address for you, and for periodically visiting
							our Website and this privacy policy to check for any changes.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							<strong>Visitors’ GDPR Rights</strong>
							If you are within the European Union, you are entitled to certain
							information and have certain rights under the General Data
							Protection Regulation. Those rights include: We will retain the
							any information you choose to provide to us until the earlier of:
							(a) you asking us to delete the information, (b) our decision to
							cease using our existing data providers, or (c) the Company
							decides that the value in retaining the data is outweighed by the
							costs of retaining it. You have the right to request access to
							your data that the Company stores and the rights to either rectify
							or erase your personal data. You have the right to seek
							restrictions on the processing of your data. You have the right to
							object to the processing of your data and the right to the
							portability of your data. To exercise these rights please email us
							at{" "}
							<a href="mailto:support@narmtraining.com">
								support@narmtraining.com
							</a>
							. To the extent that you provided consent to the Company’s
							processing of your personal data, you have the right to withdraw
							that consent at any time, without affecting the lawfulness of
							processing based upon consent that occurred prior to your
							withdrawal of consent. You have the right to lodge a complaint
							with a supervisory authority that has jurisdiction over issues
							related to the General Data Protection Regulation. We require only
							the information that is reasonably required to enter into a
							contract with you. We will not require you to provide consent for
							any unnecessary processing as a condition of entering into a
							contract with us.
						</p>
					</div>
					<div class="col-md-12 order-2 order-md-1">
						<p className="">
							<strong>Contact Us</strong>
							The Company welcomes your questions or comments regarding the
							Privacy Policy:
						</p>
						<p>
							NARM Training Institute LLC, and Kammer Health and Wellness, LLC
						</p>
						<p>
							206 Mason St., Suite E, Ukiah, California, 95482, USA. (707) 462
							2133
						</p>
						<p>
							Email Address:{" "}
							<a href="mailto:support@narmtraining.com">
								support@narmtraining.com
							</a>
						</p>
						<p>Effective as of May 24, 2018</p>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default PrivacyPolicy
